import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import appleImg from '../assets/brands/apple.png';
import currysImg from '../assets/brands/currys.png';
import { ReactComponent as Bot } from '../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../assets/icons/top-wave.svg';
import { AvailableAt } from '../components/AvailableAt/AvailableAt';
import { CalculatorHowDoesThatWork } from '../components/CalculatorHowDoesThatWork/calculator-how-does-that-work';
import { ScWave } from '../components/Calculators/styled';
import { CustomButton } from '../components/custom-button';
import { ScPage } from '../components/page/styled';
import { SEO } from '../components/Seo/Seo';
import { IBikeCalculatorValues } from '../helpers/bike-calculations';
import { MainLayout } from '../layouts/main-layout';
import { InfoSection } from '../sections/EmployeeCycling/InfoSection';
import { InviteYourEmployer } from '../sections/EmployeeCycling/InviteYourEmployer';
import { Benefits } from '../sections/EmployeeTech/Benefits';
import { Hero } from '../sections/EmployeeTech/Hero';
import { HowItWorks } from '../sections/EmployeeTech/HowItWorks';
import { TechCalculatorSection } from '../sections/EmployeeTech/TechCalculatorSection';

const EETechScheme = () => {
	const [calculatorData, setCalculatorData] = React.useState<IBikeCalculatorValues>({
		platformFee: 0,
		totalSalSac: 0,
		monthlyTakeHomePay: 0,
		taxFreePrice: 0,
		totalSavings: 0,
		totalSavingsPercentage: 0,
		netFee: 0,
		salary: 0,
		voucherValue: 0,
	});

	return (
		<MainLayout>
			<ScPage>
				<Hero />
				<AvailableAt images={[appleImg, currysImg]} />
				<TechCalculatorSection setCalculatorData={setCalculatorData} />
				<ScWave>
					<Top className="top" />
				</ScWave>
				<CalculatorHowDoesThatWork calculatorData={calculatorData} />
				<Benefits />
				<HowItWorks />
				<Bot
					style={{
						width: '100wv',
					}}
				/>
				<ScInfoSection>
					<InfoSection
						title="FAQs"
						text="Got more questions? We’ve got you covered."
						buttons={
							<Link to="/employee-faq/">
								<CustomButton>Get some answers</CustomButton>
							</Link>
						}
					/>
				</ScInfoSection>
				<InviteYourEmployer />
				<InfoSection
					isSmallText
					title="There’s more where that came from..."
					text="With Gogeta you can also save on bikes, home purchases and nursery fees"
					buttons={
						<div
							style={{
								display: 'flex',
								gap: '20px',
							}}
						>
							<Link to="/gogeta-tech-scheme/">
								<CustomButton>Help me save on home</CustomButton>
							</Link>
							<Link to="/gogeta-cycle-to-work-scheme/">
								<CustomButton>Help me save on bikes</CustomButton>
							</Link>
							<Link to="/employee-nursery/">
								<CustomButton>Help me save on nursery</CustomButton>
							</Link>
						</div>
					}
				/>
			</ScPage>
		</MainLayout>
	);
};

export default EETechScheme;

export const Head = () => (
	<SEO
		title="Gogeta Tech Scheme | Fair & Flexible Savings"
		description="Discover Gogeta's fair Cycle to Work Scheme offering great savings and flexibility for employees, effortless management for employers, and a better deal for retailers. Learn more today!"
	/>
);

export const ScInfoSection = styled.div`
	margin-top: 0;
	@media (min-width: 1220px) {
		.info-section-wrapper {
			margin-top: 100px;
		}
	}
`;
